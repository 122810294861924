import BaseService from "./base.service";

class ProductService {

    produce(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('products', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('products/'+id);
    }
    delete(products) {
		return BaseService.apiPostRequest('products/delete/', products);
    }
    add(products) {
        return BaseService.apiPostRequest('products/add', products);
    }
    update(id, products) {
        return BaseService.apiPostRequest('products/'+id, products);
    }
}
export default new ProductService();
